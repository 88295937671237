import React, { useState, useEffect } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import BootstrapTable from "react-bootstrap-table-next";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import VendorService from "../../services/vendor.services";
import TokenService from "../../services/token.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { Input } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import _ from 'lodash';

const AddServiceTerms = () => {

    const [locationData, setLocationData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const token = TokenService.getUser();
    const [serviceTermData, setServiceTermData] = useState([]);
    const [active, setActive] = useState(false);
    const [lobId, setLobId] = useState(null);
    const [lob, setLob]=useState(null);
    const [showModal, setShowModal] = useState({
        state: false,
        type: "",
    });
    const [lobOptions, setLobOptions] = useState([]); 
    const vendorUserId = token?.vendor?.vendor_user_id;
    const [termDescription, setTermDescription] = useState(null);
    const [rowId, setRowId] = useState(null);
    const [reload, setReload] = useState(true);
    const [location,setLocation]=useState(null);

    const getServiceTerm = async () => {
        try {
            setServiceTermData([]);
            const result = await VendorService.getServiceTerm({
                vendor_location_id: selectedLocation,
                lob_id: lobId
            })
            if (result.data.errorCode === 0) {
                setServiceTermData(result?.data?.data);
            } else {
                setServiceTermData([]);
            }
        }
        catch (error) {
            requestAndErrorHandler.errorHandler(error);
        };
    }

    const getVendorLocation = async (vendorUserId) => {
        await VendorService.getVendorLocation({ vendor_user_id: vendorUserId })
            .then((res) => {
                if (res?.data?.errorCode === 0) {
                    
                    setLocationData(res?.data?.data);
                }
            })
            .catch((error) => {
                requestAndErrorHandler?.errorHandler(error);
            });
    };

    const handleSelectLocation = (value) => {
        setLocation(value);
    };

    useEffect(() => {
        getServiceTerm();
    }, [reload, lobId, selectedLocation]);

    useEffect(() => {
        VendorService.getLOBIsEnable({ flag: "Service" })
            .then((res) => {
                if (res?.data?.errorCode === 0) {
                    setLobOptions(res?.data?.data);
                }
            })
            .catch((error) => {
                requestAndErrorHandler.errorHandler(error);
            });
    }, []);

    useEffect(() => {
        getVendorLocation(vendorUserId);
    }, []);

    const validateError = () => {
       
        if (_.isNil(lob) || lob === -1) {
           
            return false;
        }

        if (_.isNil(location) || location === -1) {
           
            return false;
        }
       
        if (_.isEmpty(termDescription)) {
            
            return false;
        }
    
        
        return true;
    };
    

    const handleAddServiceTerm = async () => {
        
        setActive(false);
        if (validateError())  {
            const requestData = {
                lob_id: lob,
                term_description: termDescription,
                vendor_location_id: location,
            };
            try {
               
                const res = await VendorService.addServiceTerm(requestData);
                if (res?.data?.errorCode === 0) {
                    setShowModal((prev) => ({ ...prev, state: false }));
                    setActive(false);
                    setTermDescription(null);
                    setLocation(null);
                    setLob(null);
                    const successToast = toast.success(`Slot Added Successfully`, {
                        position: "bottom-left",
                        autoClose: false,
                    });
                    setTimeout(() => {
                        toast.dismiss(successToast);
                    }, 2000);
                    setReload((val) => !val);
                    setShowModal((prev) => ({ ...prev, state: false }));
                } else {
                    const errorToast = toast.error(`${res?.data?.message}`, {
                        position: "bottom-left",
                        autoClose: false,
                    });

                    setTimeout(() => {
                        toast.dismiss(errorToast);
                    }, 2000);
                }
            } catch (error) {
                requestAndErrorHandler.errorHandler(error);
            }
        } else {
            setActive(true);
            // setShowModal((prev) => ({ ...prev, state: false }));
        }

    };

    const updateStatus = async (row_id) => {
        try {
            const result = await VendorService.updateStatus({ row_id });
            if (result?.data?.errorCode === 0) {
                getServiceTerm();
                setReload((val) => !val);
                toast.success(result?.data?.message);

            }
        } catch (error) {
            requestAndErrorHandler(error);
        }
    };

    const handleEditServiceTerms = async () => {
        if (validateError())  {
        const requestData = {
            row_id: rowId,
            lob_id: lob,
            term_description: termDescription,
            vendor_location_id: location,
        };
        try {
            const res = await VendorService.editServiceTerms(requestData);
            if (res?.data?.errorCode === 0) {
                setShowModal((prev) => ({ ...prev, state: false }));
                setActive(false);
                const successToast = toast.success(`Slot Updated Successfully`, {
                    position: "bottom-left",
                    autoClose: false,
                });
                setTimeout(() => {
                    toast.dismiss(successToast);
                }, 2000);
                setReload((val) => !val);

                setShowModal((prev) => ({ ...prev, state: false }));
                setTermDescription(null);
                setLocation(null);
                setLob(null);
            } else {
                const errorToast = toast.error(`${res?.data?.message}`, {
                    position: "bottom-left",
                    autoClose: false,
                });

                setTimeout(() => {
                    toast.dismiss(errorToast);
                }, 2000);
            }
        } catch (error) {
            requestAndErrorHandler.errorHandler(error);
        }
    }
    else {
        setActive(true);
        
    }

};


    const columns = [
        {
            dataField: "row_id",
            text: "Row NO.",
        },
        {
            dataField: "term_description",
            text: "Description",
        },
        {
            dataField: "vendor_location_id",
            text: "Vendor Location",
        },
        {
            dataField: "lob",
            text: "LOB",
        },
        {
            dataField: "is_active",
            text: "Action",
            formatter: (cellContent, row) => {
                return (
                    <>
                        <div className="d-flex gap-3">
                            <FUSButton
                                className={"action_button_style"}
                                iconSrc="md"
                                iconName="MdOutlineModeEdit"
                                iconSize={16}
                                buttonType="lightgray"
                                onClick={() => {
                                  
                                    setLob(row?.id)
                                    setTermDescription(row?.term_description)
                                    setLocation(row?.vendor_location_id);
                                    setRowId(row?.row_id)
                                    setShowModal((prev) => ({
                                        ...prev,
                                        state: true,
                                        type: "Edit Service",
                                    }))
                                }

                                }
                            />

                            <div>
                                <FUSButton
                                    buttonType={
                                        row?.is_active ? "success" : "danger"
                                    }
                                    className={"form-control"}
                                    onClick={() => {
                                        updateStatus(row?.row_id);
                                    }}
                                    labelText={
                                        row?.is_active ? "Activate" : "Deactivate"
                                    }
                                />
                            </div>
                        </div>

                    </>
                );
            },
            editable: false,
        },

    ];
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );
    const featureListOption = {
        paginationSize: 4,
        pageStartIndex: 0,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "10",
                value: 10,
            },
            {
                text: "15",
                value: 15,
            },
            {
                text: "All",
                value: 5,
            },
        ],
    };

    const handleChangeLob = (e) => {
        const value = e.target.value;
        setLobId(value);
       
      };
      const handleChangeLocation = (e) => {
        const value = e.target.value;
        setSelectedLocation(value);
      
      };

    return (
        <>
            <LayoutContainer
                title1="Configuration"
                title2="Add Service Terms"
            >

                {/* <div className="px-1 pt-2"> */}
                <div className="border_bottom_style">
                    <div className="row justify-content-between">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-4 ps-0 ms-0">
                                    <div className="form-group">
                                        <select
                                            name="filter"
                                            id="filter"
                                            className="form-select py-2  text_style"
                                            onChange={handleChangeLob}
                                        >
                                            <option value={-1}>Select LOB</option>
                                            {lobOptions?.length > 0 &&
                                                lobOptions?.map((item) => (
                                                    <option key={item?.lob_id} value={item?.lob_id}>
                                                        {item?.lob_name}
                                                    </option>
                                                ))}
                                        </select>
                                        <div className="form-group">
                                            <select
                                                name="location"
                                                id="location"
                                                className="form-select py-2  text_style"
                                                onChange={handleChangeLocation}
                                            >
                                                <option value={-1}>Select Location</option>
                                                {locationData?.length > 0 &&
                                                    locationData?.map((item) => (
                                                        <option key={item?.id} value={item?.id}>
                                                            {item?.location_name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex gap-3 justify-content-end align-items-end">
                            <div className="col-md-2 ps-0 ms-0 mt-1">
                                <FUSButton
                                    className={"py-2"}
                                    buttonType="primary"
                                    labelText={"Add Service"}
                                    onClick={() =>
                                        setShowModal((prev) => ({
                                            ...prev,
                                            state: true,
                                            type: "Add Service",
                                        }))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="px-3 py-3">
                    <div className="col-md-12">
                        <BootstrapTable
                            classes="border border-1"
                            keyField="id"
                            data={serviceTermData}
                            columns={columns}
                            pagination={paginationFactory(featureListOption)}
                            filter={filterFactory()}
                        />
                    </div>
                </div>
                {showModal.state && (
                    <FUSModal
                        title={
                            showModal.type === "Add Service"
                                ? "Add Service"
                                : "Edit Service"
                        }
                        showModal={showModal.state}
                        size={"md"}
                        handleClose={() => {
                            setTermDescription(null);
                    setLocation(null);
                    setLob(null);
                            setShowModal((prev) => ({ ...prev, state: false, type: "" }));
                            // setLobId(null);
                            setActive(false);
                        }}

                        centered
                    >
                        {showModal.type === "Add Service" && (
                            <div className="row">
                                <>
                                    <div>
                                        <div className="col-md-12 px-0 mt-3">
                                            <select
                                                name="filter"
                                                id="filter"
                                                className="form-select py-2  text_style"
                                                onChange={(e) => {setLob(e.target.value) }}
                                                value={lob}
                                            >
                                                <option value={null} >Select LOB</option>
                                                {lobOptions?.length > 0 &&
                                                    lobOptions?.map((item) => (
                                                        <option key={item?.lob_id} value={item?.lob_id}>
                                                            {item?.lob_name}
                                                        </option>
                                                    ))}
                                            </select>
                                            {active && _.isNil(lob) && (
                                                <p className="text-danger">Please select a lob</p>
                                            )}
                                        </div>
                                        <div className="col-md-12 px-0 mt-4">

                                            <select
                                                name="location"
                                                id="location"
                                                className="form-select py-2 text_style"
                                                onChange={(e) => handleSelectLocation(e.target.value)}
                                                value={location}
                                            >
                                                <option value={null}>Select Location</option>
                                                {locationData?.length > 0 &&
                                                    locationData?.map((item) => (
                                                        <option key={item.id} value={item?.id}>
                                                            {item?.location_name}
                                                        </option>
                                                    ))}
                                            </select>
                                            {active && _.isNil(location)&&(
                                                <p className="text-danger">Please select a location</p>
                                            )}

                                        </div>
                                        <div className="col-md-12 px-0 mt-3">
                                            <label>Term Description</label>
                                            <div>
                                                <Input
                                                    type="text"
                                                    name="term_description"
                                                    // value={e.target.value}
                                                    onChange={(e) => setTermDescription(e.target.value)}
                                                    className="form-control text_style mt-1 "
                                                    required={true}
                                                    placeholder="Enter Term Description"
                                                />
                                                {active && _.isEmpty(termDescription) && (
                                                    <p className="text-danger">Please enter a description</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-md-12 px-0 mt-3 mb-1">
                                            <FUSButton
                                                labelText={"Add Service Terms"}
                                                className={"form-control"}
                                                buttonType="primary"
                                                onClick={() => handleAddServiceTerm()}
                                            ></FUSButton>
                                        </div>
                                    </div>
                                </>
                            </div>
                        )}
                        {showModal.type === "Edit Service" && (
                            <div className="row">
                                <>
                                    <div>
                                        <div className="col-md-12 px-0 mt-3">
                                            <select
                                                name="filter"
                                                id="filter"
                                                className="form-select py-2  text_style"
                                                onChange={(e) => setLob(e.target.value)}
                                                value={lob}
                                            >
                                                <option value={null}>Select LOB</option>
                                                {lobOptions?.length > 0 &&
                                                    lobOptions?.map((item) => (
                                                        <option key={item?.lob_id} value={item?.lob_id}>
                                                            {item?.lob_name}
                                                        </option>
                                                    ))}
                                            </select>
                                            {active && _.isNil(lob) && (
                                                <p className="text-danger">Please select a lob</p>
                                            )}
                                            
                                        </div>
                                        <div className="col-md-12 px-0 mt-4">

                                            <select
                                                name="location"
                                                id="location"
                                                className="form-select py-2 text_style"
                                                onChange={(e) => handleSelectLocation(e.target.value)}
                                                value={location}
                                            >
                                                <option value={null}>Select Location</option>
                                                {locationData?.length > 0 &&
                                                    locationData?.map((item) => (
                                                        <option key={item.id} value={item?.id}>
                                                            {item?.location_name}
                                                        </option>
                                                    ))}
                                            </select>
                                            {active && _.isNil(location) && (
                                                <p className="text-danger">Please select a location</p>
                                            )}

                                        </div>
                                        <div className="col-md-12 px-0 mt-3">
                                            <label>Term Description</label>
                                            <div>
                                                <Input
                                                    type="text"
                                                    name="term_description"
                                                    value={termDescription}
                                                    onChange={(e) => setTermDescription(e.target.value)}
                                                    className="form-control text_style mt-2 fs-5"
                                                    required={true}
                                                    placeholder="Enter Term Description"
                                                />
                                                {active && _.isEmpty(termDescription) && (
                                                    <p className="text-danger">Please enter a description</p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-md-12 px-0 mt-3 mb-1">
                                            <FUSButton
                                                labelText={"Edit Service Terms"}
                                                className={"form-control"}
                                                buttonType="primary"
                                                onClick={() => handleEditServiceTerms()}
                                            ></FUSButton>
                                        </div>
                                    </div>
                                </>
                            </div>
                        )}
                    </FUSModal>
                )}
            </LayoutContainer>
        </>
    );
};

export default AddServiceTerms;
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import "./AddProductItem.css";
import ProductService from "../../../services/product.service";
import requestAndErrorHandler from "../../../services/responseAndErrorHandler";
import TokenService from "../../../services/token.service";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  categoryValidation,
  itemValidation,
  productValidation,
} from "../../../validation/validation";
import { FUSButton } from "../../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { ROLES } from "../../../constants/constants";
const AddProductItem = ({ handleClose, actionType, setFlag, vendorID }) => {
  const token = TokenService.getUser();
  const [toggleType, setToggleType] = useState("addItem");
  const [categoryName, setCategoryName] = useState("");
  const [productLOB, setProductLOB] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [selectedLOB, setSelectedLOB] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [allAttributesByCategory, setAttributesByCategory] = useState([]);
  const [productDetails, setProductDetails] = useState({
    category_id: null,
    product_name: "",
  });

  const [itemDetails, setItemDetails] = useState({
    product_id: null,
    description: null,
    sku: null,
    qty: null,
    price: null,
    item_attributes: [],
    igst:null,
    sgst:null,
    cgst:null,
    cess:null
  });

  const [errors, setErrors] = useState({});

  const handleCategory = (e) => {
    setProductDetails((prev) => ({
      ...prev,
      category_id: e.target.value,
    }));
  };

  const addItemByVendor = async (itemObj) => {
    try {
      const res = await ProductService.addItemByVendor({
        item_details: itemObj,
      });

      if (res?.data?.errorCode === 0) {
        const successToast = toast.success(`${res?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setFlag((flagValue) => flagValue);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleSubmission = () => {
    const completeItemDetails = {
      ...itemDetails,
      lob_id: selectedLOB,
      category_id: productDetails.category_id,
    };
    const validationErrors = itemValidation(completeItemDetails);
    if (_.isEmpty(validationErrors)) {
      addItemByVendor(itemDetails);
      handleClose();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const getAllLOB = useCallback(async () => {
    let vendor_id;
    if (token?.role?.role_id === ROLES.PRODUCT_MANANGEMENT) {
      vendor_id = vendorID.id;
    } else {
      vendor_id = token?.vendor?.id;
    }
    try {
      const result = await ProductService.getLOB({
        vendor_id: vendor_id,
      });
      if (result?.data?.errorCode === 0) {
        setProductLOB(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  }, []);

  const getProductCategoryByLOBID = useCallback(async () => {
    if (selectedLOB) {
      try {
        const result = await ProductService.getProductCategoryByLOBID(
          selectedLOB
        );
        if (result?.data?.errorCode === 0) {
          setProductCategory(result?.data?.data);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  }, [selectedLOB]);

  const getAllProductsByCategoryId = useCallback(async () => {
    let vendor_id;
    if (token?.role?.role_id === ROLES.PRODUCT_MANANGEMENT) {
      vendor_id = vendorID?.vendor_user_id;
    } else {
      vendor_id = token?.agent?.agent_user_id;
    }
    if (productDetails?.category_id) {
      try {
        const result = await ProductService.getAllProductsByCategoryId({
          category_id: parseInt(productDetails.category_id),
          vendor_id: vendor_id,
        });
        if (result?.data?.errorCode === 0) {
          setAllProducts(result?.data?.data);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  }, [productDetails?.category_id]);

  const getAttributesByCategory = useCallback(async () => {
    if (productDetails?.category_id) {
      try {
        const result = await ProductService.getAttributesByCategory({
          category_id: productDetails.category_id,
        });
        if (result?.data?.errorCode === 0) {
          setAttributesByCategory(result?.data?.data);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  }, [productDetails?.category_id]);

  useEffect(() => {
    getAllLOB();
  }, [getAllLOB]);

  useEffect(() => {
    getProductCategoryByLOBID();
  }, [ selectedLOB]);

  useEffect(() => {
      getAllProductsByCategoryId();
      getAttributesByCategory();
  }, [productDetails?.category_id]);



  useEffect(() => {
    actionType(toggleType);
  }, [toggleType]);

  const handleSaveCategory = async (params) => {
    const categoryDetails = {
      lob_id: selectedLOB,
      category_name: categoryName,
    };
    const validationErrors = categoryValidation(categoryDetails);
    if (!_.isEmpty(validationErrors)) {
      setErrors(validationErrors);
      return;
    } else {
      try {
        const result = await ProductService.addProductCategoryByLOBID({
          lob_id: selectedLOB,
          category_name: categoryName,
        });
        if (result.data.errorCode === 0) {
          setCategoryName("");
          setSelectedLOB("");
          handleClose();
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const handleProduct = (productData) => {
    try {
      const result = ProductService.addProduct(productData);
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleAddProduct = async () => {
    const completeProductDetails = {
      ...productDetails,
      lob_id: selectedLOB,
    };

    const validationErrors = productValidation(completeProductDetails);
    if (_.isEmpty(validationErrors)) {
      handleProduct(completeProductDetails);
      handleClose();
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div>
      {toggleType === "addCategory" ? (
        <>
          <div className="row px-0 mt-3">
            <div className="col-md-12 px-0 m-0 ">
              <div>
                <span className="text-secondary">LOB</span>
                <select
                  id="lob"
                  className="form-select mt-1 text_style"
                  value={selectedLOB}
                  onChange={(e) => setSelectedLOB(e.target.value)}
                >
                  <option value="">Select</option>
                  {productLOB.length > 0 &&
                    productLOB.map((lob, index) => (
                      <option key={index} value={lob.lob_id}>
                        {lob.lob}
                      </option>
                    ))}
                </select>
                <span className="text-danger">{errors.lob_id}</span>
              </div>
            </div>
            <div className="col-md-12 px-0 mt-2">
              <div>
                <label className="text-secondary">Category Name</label>
                <input
                  type="text"
                  className="form-control text_style mt-1"
                  id="exampleFormControlInput1"
                  placeholder="Enter here..."
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
                <span className="text-danger">{errors.categoryName}</span>
              </div>
            </div>
          </div>
          <div className="mt-1">
            <FUSButton
              className={"form-control py-2 mt-3"}
              labelText={"Save"}
              buttonType="primary"
              onClick={() => handleSaveCategory()}
            />
          </div>
        </>
      ) : toggleType === "addItem" ? (
        <>
          <div className="row">
            <div className="col-md-12 px-0 m-0 ">
              <div>
                <span className="text-secondary">Select LOB</span>
                <select
                  id="lob"
                  className="form-select mt-1 text_style"
                  value={selectedLOB}
                  onChange={(e) => setSelectedLOB(e.target.value)}
                >
                  <option value="">Select</option>
                  {productLOB.length > 0 &&
                    productLOB.map((lob, index) => (
                      <option key={index} value={lob.lob_id}>
                        {lob.lob}
                      </option>
                    ))}
                </select>
                <span className="text-danger">{errors.lob_id}</span>
              </div>
            </div>
            <div className="col-md-12 px-0 m-0">
              <div class="form-group">
                <label>Select Category</label>
                <select
                  id="subCategory"
                  className="form-select mt-1 text_style"
                  value={productDetails.category_id}
                  onChange={(e) => handleCategory(e)}
                  disabled={!selectedLOB}
                >
                  <option value="">Select</option>
                  {productCategory.map((item) => (
                    <option key={item.category_name} value={item.category_id}>
                      {item.category_name}
                    </option>
                  ))}
                </select>
                <span className="text-danger">{errors.category_id}</span>
              </div>
              <div className="float-end mx-2 mt-1">
                <a
                  className="color_style"
                  href="#"
                  onClick={(e) => setToggleType("addCategory", e)}
                >
                  Add category
                </a>
              </div>
            </div>
          </div>
          {/* product  */}
          <div className="mt-2">
            <div className="row justify-content-between">
              <div className="col-md-12 px-0 m-0">
                <div class="form-group">
                  <label>Select Product</label>
                  <select
                    id="product"
                    className="form-select"
                    value={itemDetails.product_id}
                    onChange={(e) =>
                      setItemDetails((prev) => ({
                        ...prev,
                        product_id: e.target.value,
                      }))
                    }
                    disabled={!productDetails.category_id}
                  >
                    <option value="">Select</option>
                    {allProducts.map((item) => (
                      <option key={item.name} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <span className="text-danger">{errors.product_id}</span>
                </div>
                <div className="float-end mx-2 mt-1">
                  <a
                    className="color_style"
                    href="#"
                    onClick={(e) => setToggleType("addProduct", e)}
                  >
                    Add product
                  </a>
                </div>
              </div>
            </div>
          </div>
          {itemDetails.product_id ? (
            <>
              <div className="row justify-content-between">
                <div className="col-md-6 ps-0 m-0">
                  <div className="form-group">
                    <label htmlFor="">SKU Code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="skuCode"
                      id="sku-code"
                      value={itemDetails.sku}
                      onChange={(e) =>
                        setItemDetails((prev) => ({
                          ...prev,
                          sku: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <span className="text-danger">{errors.sku}</span>
                </div>
                <div className="col-md-6 ps-0 m-0">
                  <div className="form-group">
                    <label htmlFor="">Quantity</label>
                    <input
                      type="text"
                      className="form-control"
                      name="qty"
                      id="qty"
                      value={itemDetails.qty}
                      onChange={(e) =>
                        setItemDetails((prev) => ({
                          ...prev,
                          qty: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <span className="text-danger">{errors.qty}</span>
                </div>
              </div>

              <div className="row justify-content-between">
                <div className="col-md-12 ps-0 m-0">
                  <div className="form-group">
                    <label htmlFor="">Price</label>
                    <input
                      type="text"
                      className="form-control"
                      value={itemDetails.price}
                      onChange={(e) =>
                        setItemDetails((prev) => ({
                          ...prev,
                          price: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <span className="text-danger">{errors.price}</span>
                </div>
              </div>

              <div className="row justify-content-between">
                <div className="col-md-6 ps-0 m-0">
                  <div className="form-group">
                    <label htmlFor="">IGST</label>
                    <input
                      type="text"
                      className="form-control"
                      value={itemDetails.igst}
                      onChange={(e) =>
                        setItemDetails((prev) => ({
                          ...prev,
                          igst: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 m-0">
                  <div className="form-group">
                    <label htmlFor="">SGST</label>
                    <input
                      type="text"
                      className="form-control"
                      value={itemDetails.tax}
                      onChange={(e) =>
                        setItemDetails((prev) => ({
                          ...prev,
                          sgst: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 m-0">
                  <div className="form-group">
                    <label htmlFor="">CGST</label>
                    <input
                      type="text"
                      className="form-control"
                      value={itemDetails.cgst}
                      onChange={(e) =>
                        setItemDetails((prev) => ({
                          ...prev,
                          cgst: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 ps-0 m-0">
                  <div className="form-group">
                    <label htmlFor="">CESS</label>
                    <input
                      type="text"
                      className="form-control"
                      value={itemDetails.cess}
                      onChange={(e) =>
                        setItemDetails((prev) => ({
                          ...prev,
                          cess: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>

                {/* <div className="col-md-6 ps-0 m-0">
                  <div className="form-group">
                    <label htmlFor="">Discount</label>
                    <input
                      type="text"
                      className="form-control"
                      value={itemDetails.discount_percentage}
                      onChange={(e) =>
                        setItemDetails((prev) => ({
                          ...prev,
                          discount_percentage: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div> */}
              </div>

              <div className="row justify-content-between">
                {allAttributesByCategory &&
                  allAttributesByCategory.map((attr, ind) => {
                    return (
                      <>
                        <div className="col-md-6 ps-0 mt-3">
                          <div className="form-group">
                            <label htmlFor="">{attr.attribute_name}</label>
                            <select
                              name="product_attribute"
                              id=""
                              className="form-control"
                              onChange={(e) =>
                                setItemDetails((prev) => ({
                                  ...prev,
                                  item_attributes: [
                                    ...prev.item_attributes,
                                    {
                                      attribute_name: attr.attribute_name,
                                      attribute_value: e.target.value,
                                    },
                                  ],
                                }))
                              }
                            >
                              <option value="">Select</option>
                              {attr?.default_value.map((attrItem) => {
                                return (
                                  <>
                                    <option value={attrItem}>{attrItem}</option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
              <div className="row justify-content-between">
                <div className="col-md-12 ps-0  mt-3">
                  <div className="form-group">
                    <label htmlFor="">Description</label>
                    <textarea
                      className="form-control"
                      name="description"
                      id="description"
                      rows="3"
                      value={itemDetails.description}
                      onChange={(e) =>
                        setItemDetails((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <span className="text-danger">{errors.description}</span>
                </div>
              </div>
            </>
          ) : null}
          <div className="mt-2">
            <div class="row mt-3">
              <div className="col-md-6 ps-0 m-0">
                <FUSButton
                  className={"form-control py-2"}
                  labelText={"Cancel"}
                  buttonType="secondary"
                  type="button"
                  onClick={handleCancel}
                />
              </div>
              <div className="col-md-6 pe-0 m-0">
                <FUSButton
                  className={"form-control py-2"}
                  labelText={"Submit"}
                  buttonType="primary"
                  type="button"
                  onClick={() => handleSubmission()}
                />
              </div>
            </div>
          </div>
        </>
      ) : toggleType === "addProduct" ? (
        <>
          <div className="row mt-2 ">
            <div className="col-md-12 px-0 ">
              <div class="form-group">
                <label>Select LOB</label>
                <select
                  id="lob"
                  className="form-select"
                  value={selectedLOB}
                  onChange={(e) => setSelectedLOB(e.target.value)}
                >
                  <option value="">Select</option>
                  {productLOB.length > 0 &&
                    productLOB.map((lob, index) => (
                      <option key={index} value={lob.lob_id}>
                        {lob.lob}
                      </option>
                    ))}
                </select>
                <span className="text-danger">{errors.lob_id}</span>
              </div>
            </div>

            <div className="col-md-12 px-0 mt-2">
              <div class="form-group">
                <label className="text-secondary">Select Category</label>
                <select
                  id="subCategory"
                  className="form-select mt-1 text_style"
                  value={productDetails.category_id}
                  onChange={(e) =>
                    setProductDetails((prev) => ({
                      ...prev,
                      category_id: e.target.value,
                    }))
                  }
                  disabled={!selectedLOB}
                >
                  <option value="">Select</option>
                  {productCategory.map((item) => (
                    <option key={item.category_name} value={item.category_id}>
                      {item.category_name}
                    </option>
                  ))}
                </select>
                <span className="text-danger">{errors.category_id}</span>
              </div>
            </div>

            <div className="col-md-12 px-0 mt-3">
              <div>
                <span className="text-secondary">Product Name</span>
                <input
                  type="text"
                  id="product"
                  placeholder="Enter here..."
                  className="form-control text_style mt-1"
                  value={productDetails.product_name}
                  onChange={(e) =>
                    setProductDetails((prev) => ({
                      ...prev,
                      product_name: e.target.value,
                    }))
                  }
                  disabled={!productDetails.category_id}
                />
                <span className="text-danger">{errors.product_name}</span>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="row justify-content-between mt-2 ">
              <div className="col-md-6 ps-0">
                <FUSButton
                  className={"form-control"}
                  buttonType="secondary"
                  labelText={"Cancel"}
                  type="button"
                  onClick={handleCancel}
                />
              </div>
              <div className="col-md-6 m-0 pe-0">
                <FUSButton
                  className={"form-control"}
                  labelText={"Save"}
                  buttonType="primary"
                  type="button"
                  onClick={() => handleAddProduct()}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AddProductItem;

import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import { Col, Row } from "react-bootstrap";
import OfferService from "../../services/offer.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import VendorService from "../../services/vendor.services";
import _ from "lodash";

const SectionConfiguration = () => {
  const [allUINames, setAllUINames] = useState([]);
  const [selectedUIName, setSelectedUIName] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [allLob, setAllLob] = useState([]);
  const [editedSectionText, setEditedSectionText] = useState(null);
  const [editedUiPosition, setEditedUiPosition] = useState(null);
  const [editedMaxItem, setEditedMaxItem] = useState(null);
  const [editedsectionId, setEditedsectionId] = useState(null);
  const [showModal, setShowModal] = useState({
    state: false,
    type: null,
  });
  const [showEditModal, setEditShowModal] = useState({
    state: false,
    type: "",
  });

  const initialFormData = {
    section_name: "",
    display_sec_text: "",
    section_type: "",
    is_image_uploaded: "",
    is_title: "",
    is_description: "",
    ui_position: "",
    max_items: "",
    lob_id: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "section_type" && value === "multi_item_card") {
      setFormData((prev) => ({
        ...prev,
        max_items: "100",
        [name]:
          name === "is_image_uploaded" ||
          name === "is_title" ||
          name === "is_description"
            ? value === "true"
            : value,
      }));
    } else if (name === "section_type" && value === "multi_offer_card") {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        is_image_uploaded: "false",
        is_title: "false",
        is_description: "false",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]:
          name === "is_image_uploaded" ||
          name === "is_title" ||
          name === "is_description"
            ? value === "true"
            : value,
      }));
    }
  };
  const getLOBForSuperUser = async () => {
    try {
      const result = await VendorService.getLOBForSuperUser();
      if (result?.data?.errorCode === 0) {
        const lobData = result?.data?.data;
        setAllLob(lobData?.filter((item) => item.is_active == true));
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getLOBForSuperUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      _.isEmpty(formData.display_sec_text) ||
      _.isEmpty(formData?.section_type) ||
      _.isEmpty(formData?.ui_position)
    ) {
      const errorToast = toast.error("Please fill all mandatory fields.", {
        position: "bottom-left",
        autoClose: false,
      });
      setTimeout(() => {
        toast.dismiss(errorToast);
      }, 2000);
      return;
    }

    const dataToSend = {
      ...formData,
      is_image_uploaded: formData.is_image_uploaded,
      is_title: formData.is_title,
      is_description: formData.is_description,
      ui_id: selectedUIName,
    };

    try {
      const result = await OfferService.addAppUIConfigDetails(dataToSend);
      if (result?.data?.errorCode === 201) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setShowModal((prev) => ({
          ...prev,
          state: false,
          type: "",
        }));
        setFormData(initialFormData);
        handleUINameSelect(selectedUIName);
      } else {
        const successToast = toast.error(`${result?.data?.message}`, {
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 3000);
        setShowModal((prev) => ({
          ...prev,
          state: false,
          type: "",
        }));
        setFormData(initialFormData);
        handleUINameSelect(selectedUIName);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getUIConfig = async () => {
    try {
      const result = await OfferService.getUIConfig();
      if (result?.data?.errorCode === 0) {
        setAllUINames(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleUINameSelect = (id) => {
    setSelectedUIName(id);
    OfferService.getAppUIConfigDetailsByUI_Id({ ui_id: id })
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setTableData(res?.data?.data);
        } else {
          setTableData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    setShowModal((prev) => ({ ...prev, state: false }));
  };

  useEffect(() => {
    getUIConfig();
  }, []);
  const handleDelete = async (section_id) => {
    const isDeleteConfirm = window.confirm("Do you want to delete");
    if (isDeleteConfirm) {
      try {
        const result = await OfferService.deleteSectionDataBySectionID({
          section_id: section_id,
        });
        if (result?.data?.errorCode === 0) {
          handleUINameSelect(selectedUIName);
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const columns = [
    { dataField: "section_name", text: "Section Name", sort: true },
    { dataField: "display_sec_text", text: "Section Text", sort: true },
    { dataField: "lob", text: "Lob", sort: true },
    { dataField: "section_type", text: "Section Type", sort: true },
    { dataField: "is_image_uploaded", text: "Image Uploaded", sort: true },
    { dataField: "is_title", text: "Show Title", sort: true },
    { dataField: "is_description", text: "Show Description", sort: true },
    { dataField: "ui_position", text: "UI Position", sort: true },
    { dataField: "max_items", text: "Max Items", sort: true },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdOutlineModeEdit"
                  iconSize={15}
                  buttonType="lightgray"
                  onClick={() => handleEditClick(row)}
                />
              </div>
              <div>
                <FUSButton
                  className={"action_button_style"}
                  iconSrc="md"
                  iconName="MdDeleteForever"
                  iconSize={15}
                  buttonType="lightdanger"
                  onClick={() => handleDelete(row.section_id)}
                />
              </div>
            </div>
          </>
        );
      },
      editable: false,
    },
  ];
  const handleEditClick = (row) => {
    setEditShowModal((prev) => ({
      ...prev,
      state: true,
      type: "SECTIONCONFIGEDIT",
    }));
    setEditedSectionText(row.display_sec_text);
    setEditedMaxItem(row.max_items);
    setEditedsectionId(row.section_id);
    setEditedUiPosition(row.ui_position);
  };

  const handleUpdate = async () => {
    try {
      const result = await OfferService.updateSectionConfigration({
        editedSectionText: editedSectionText,
        editedUiPosition: editedUiPosition,
        editedMaxItem: editedMaxItem,
        editedsectionId: editedsectionId,
      });
      if (result?.data?.errorCode === 0) {
        setEditShowModal((prev) => ({ ...prev, state: false }));
        handleUINameSelect(selectedUIName);
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      } else {
        const successToast = toast.error(`${result?.data?.message}`, {
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 3000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  return (
    <LayoutContainer
      title1="Vendor UI Configuration"
      title2="Vendor UI Configuration"
      title3="Section Configuration"
    >
      <div className="py-1 px-1">
        <div className="border_bottom_color">
          <div className="row">
            <div className="col-md-3">
              <select
                className="form-select text_style mt-1"
                aria-label="Default select example"
                onChange={(e) => handleUINameSelect(e.target.value)}
              >
                <option value="" selected>
                  Select Display Name
                </option>
                {allUINames &&
                  allUINames.map((names, i) => (
                    <option value={names?.id} key={i}>
                      {names?.display_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-9 d-flex justify-content-end gap-3">
              {selectedUIName && (
                <FUSButton
                  iconSrc={"fa6"}
                  iconName={"FaPlus"}
                  iconSize={14}
                  labelText={"Configure Screen"}
                  buttonType="primary"
                  onClick={() => {
                    setShowModal((prev) => ({
                      ...prev,
                      state: true,
                      type: "ADD",
                    }));
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                    }));
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {showModal.state && (
          <FUSModal
            title={"Configure Screen"}
            showModal={showModal.state}
            size={"md"}
            handleClose={() => {
              setShowModal((prev) => ({ ...prev, state: false }));
              setFormData(initialFormData);
              handleUINameSelect(selectedUIName);
            }}
            centered
          >
            {showModal.type === "ADD" && (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="form-group mt-3">
                      <label>Section Name</label>
                      <input
                        type="text"
                        name="section_name"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter section name"
                        value={formData.section_name}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="form-group mt-3">
                      <label>Section Text</label>
                      <input
                        type="text"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter section text"
                        value={formData.display_sec_text}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="form-group mt-3">
                      <label>Section Type</label>
                      <select
                        name="section_type"
                        className="form-control form-select form-control-sm mt-1"
                        value={formData.section_type}
                        onChange={handleChange}
                      >
                        <option value="">Select Section Type</option>
                        <option value="offer_card">Offer Card</option>
                        <option value="item">Item</option>
                        <option value="banner">Banner</option>
                        <option value={"video_section"}>Video Section</option>
                        <option value={"item_card"}>Item cards</option>
                        <option value={"ride_banner"}>Ride Banner</option>
                        <option value={"multi_item_card"}>
                          Multi Item Card
                        </option>
                        <option value={"multi_offer_card"}>
                          Multi Offer Card
                        </option>
                      </select>
                    </div>
                  </Col>
                  {formData.section_type == "item" && (
                    <Col xs={12} md={6}>
                      <div className="form-group mt-3">
                        <label>Select Lob</label>
                        <select
                          name="lob_id"
                          className="form-control form-select form-control-sm mt-1"
                          value={formData?.lob_id}
                          onChange={handleChange}
                        >
                          <option value="">Select Lob</option>
                          {allLob.length > 0 &&
                            allLob.map((item, index) => {
                              return (
                                <option value={item?.id}>{item?.lob}</option>
                              );
                            })}
                        </select>
                      </div>
                    </Col>
                  )}
                  <Col xs={12} md={6}>
                    <div className="form-group mt-3">
                      <label>Image Uploaded</label>
                      <select
                        name="is_image_uploaded"
                        className="form-control form-select form-control-sm mt-1"
                        value={formData.is_image_uploaded}
                        onChange={handleChange}
                        disabled={formData.section_type === "multi_offer_card"}
                      >
                        <option value="">Select</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="form-group mt-3">
                      <label>Show Title</label>
                      <select
                        name="is_title"
                        className="form-control form-select form-control-sm mt-1"
                        value={formData.is_title}
                        onChange={handleChange}
                        disabled={formData.section_type === "multi_offer_card"}
                      >
                        <option value="">Select</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="form-group mt-3">
                      <label>Show Description</label>
                      <select
                        name="is_description"
                        className="form-control form-control-sm form-select mt-1"
                        value={formData.is_description}
                        onChange={handleChange}
                        disabled={formData.section_type === "multi_offer_card"}
                      >
                        <option value="">Select</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="form-group mt-3">
                      <label>UI Position</label>
                      <select
                        name="ui_position"
                        className="form-control form-select form-control-sm"
                        value={formData.ui_position}
                        onChange={handleChange}
                      >
                        <option value="">Select Position</option>
                        {Array.from({ length: 20 }, (_, i) => i + 1).map(
                          (num) => (
                            <option key={num} value={num}>
                              {num}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    {formData.section_type ===
                    "multi_item_card" ? null : formData.section_type ===
                      "multi_offer_card" ? (
                      <div className="form-group mt-3">
                        <label>Max Items</label>
                        <select
                          name="max_items"
                          className="form-control form-select form-control-sm"
                          value={formData.max_items}
                          onChange={handleChange}
                        >
                          <option value="">Select No. of items</option>
                          {[4, 8, 12].map((num) => (
                            <option key={num} value={num}>
                              {num}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <div className="form-group mt-3">
                        <label>Max Items</label>
                        <select
                          name="max_items"
                          className="form-control form-select form-control-sm"
                          value={formData.max_items}
                          onChange={handleChange}
                        >
                          <option value="">Select No. of items</option>
                          {Array.from({ length: 20 }, (_, i) => i + 1).map(
                            (num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex justify-content-center align-item-center gap-2 mt-4">
                    <FUSButton
                      buttonTextStyle="fs-6"
                      className={"py-2 px-5"}
                      labelText={"Cancel"}
                      buttonType="danger"
                      onClick={handleCancel}
                    />
                    <FUSButton
                      buttonTextStyle="fs-6"
                      className={"py-2 px-5"}
                      buttonType="primary"
                      type="submit"
                      labelText={"Add"}
                    />
                  </div>
                </Row>
              </form>
            )}
          </FUSModal>
        )}
        {showEditModal.state && (
          <FUSModal
            title={
              showEditModal.type === "SECTIONCONFIGEDIT"
                ? "Edit Configuration "
                : ""
            }
            showModal={showEditModal.state}
            size={"md"}
            handleClose={() => {
              setEditShowModal((prev) => ({ ...prev, state: false }));
              setEditedUiPosition(null);
              setEditedMaxItem(null);
              setEditedSectionText(null);
            }}
            centered
          >
            {showEditModal.type === "SECTIONCONFIGEDIT" && (
              <div className="px-3 col py-3">
                <div className="row">
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Section Text</label>
                      <input
                        type="text"
                        name="section_name"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter section name"
                        value={editedSectionText}
                        onChange={(e) => setEditedSectionText(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>UI Position</label>
                      <select
                        name="ui_position"
                        className="form-control form-select form-control-sm"
                        onChange={(e) => setEditedUiPosition(e.target.value)}
                        value={editedUiPosition}
                      >
                        <option value="">Select Position</option>
                        {Array.from({ length: 20 }, (_, i) => i + 1).map(
                          (num) => (
                            <option key={num} value={num}>
                              {num}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Max Item</label>
                      <select
                        name="ui_position"
                        className="form-control form-select form-control-sm"
                        value={editedMaxItem}
                        onChange={(e) => setEditedMaxItem(e.target.value)}
                      >
                        <option value="">Select Position</option>
                        {Array.from({ length: 20 }, (_, i) => i + 1).map(
                          (num) => (
                            <option key={num} value={num}>
                              {num}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-3 mt-3 px-0">
                  <FUSButton
                    labelText={"Update"}
                    buttonType="primary"
                    className={"form-control py-2"}
                    onClick={() => handleUpdate()}
                  ></FUSButton>
                </div>
              </div>
            )}
          </FUSModal>
        )}

        {selectedUIName && (
          <div className="px-1">
            <div className="pb-5 mb-5 px-2">
              <BootstrapTable
                classes="border border-1"
                keyField="section_id"
                columns={columns}
                data={tableData}
              />
            </div>
          </div>
        )}
      </div>
    </LayoutContainer>
  );
};

export default SectionConfiguration;

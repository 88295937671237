import React, { useEffect, useState } from "react";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import { toast } from "react-toastify";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import _ from "lodash";
import OfferService from "../../services/offer.service";

const OfferLOBCategoryMap = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [lobList, setLobList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [selectedLOB, setSelectedLOB] = useState(-1);
  const [mappingData, setMappingData] = useState([]);
  const [showModal, setShowModal] = useState({
    state: false,
  });
  const [approve, setApprove] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const filterCategories = (categories) => {
    return categories.filter((category) =>
      category.category_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const getAllCategories = async () => {
    await OfferService.getAllCategories()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setCategoryList(res?.data?.data);
        } else {
          setCategoryList([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const getLOBForSuperUser = async () => {
    try {
      const result = await OfferService.getOfferLOBForSuperUser();
      if (result?.data?.errorCode === 0) {
        setLobList(result?.data?.data);
      } else {
        setLobList([]);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleDelete = async (offer_lob_id, category_id) => {
    const isDeleteConfirm = window.confirm("Do you want to delete");
    if (isDeleteConfirm) {
      try {
        const result = await OfferService.deleteOfferLOBMapping({
          offer_lob_id: offer_lob_id,
          category_id: category_id,
        });
        if (result?.data?.errorCode === 0) {
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
          setApprove((approve) => !approve);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };

  const handleVendorLOBMapping = async () => {
    const postdata = {
      offer_lob_id: selectedLOB,
      category_id: selectedCategory,
    };
    try {
      const result = await OfferService.insertOfferLOBCategoryMapping(postdata);
      if (result?.data?.errorCode === 0) {
        toast.success(result?.data?.message, {
          position: "bottom-left",
          autoClose: 2000,
        });
        setApprove((approve) => !approve);
        setSelectedCategory(-1);
        setSelectedLOB(-1);
        setShowModal({ state: false });
      } else {
        toast.error(result?.data?.message, {
          position: "bottom-left",
          autoClose: 2000,
        });
        setSelectedCategory(-1);
        setSelectedLOB(-1);
        setShowModal({ state: false });
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const fetchMappingData = async () => {
    await OfferService.getOfferLOBCategoryMappingData()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setMappingData(res?.data?.data);
        } else {
          setMappingData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  useEffect(() => {
    fetchMappingData();
  }, [approve]);

  useEffect(() => {
    getAllCategories();
    getLOBForSuperUser();
  }, []);
  return (
    <>
      <div className="col-md-12 d-flex justify-content-end px-4">
        <FUSButton
          iconSrc={"fa6"}
          iconName={"FaPlus"}
          iconSize={14}
          labelText={"Add Mapping"}
          buttonType="primary"
          onClick={() => setShowModal((prev) => ({ ...prev, state: true }))}
        ></FUSButton>
      </div>
      <div className="px-3 mt-3 mx-1">
        <table className="table rounded rounded-2  border border-1 table-hover table-bordered">
          <thead>
            <th>Offer LOB</th>
            <th>Category Name</th>
            <th>Action</th>
          </thead>
          <tbody>
            {mappingData?.length > 0 &&
              mappingData?.map((item, index) =>
                item?.categories?.map((category, lobIndex) => (
                  <tr key={`${index}-${lobIndex}`}>
                    {lobIndex === 0 ? (
                      <td rowSpan={item?.categories?.length}>
                        {item?.lob_name}
                      </td>
                    ) : null}
                    <td>{category?.category_name}</td>
                    <td>
                      <FUSButton
                        className={"action_button_style"}
                        iconSrc="md"
                        iconName="MdDeleteForever"
                        iconSize={15}
                        buttonType="lightdanger"
                        onClick={() =>
                          handleDelete(
                            item?.offer_lob_id,
                            category?.category_id
                          )
                        }
                      />
                    </td>
                  </tr>
                ))
              )}
          </tbody>
        </table>
      </div>

      {showModal.state && (
        <FUSModal
          title={"Add Mapping"}
          showModal={showModal.state}
          size={"md"}
          handleClose={() => {
            setShowModal((prev) => ({ ...prev, state: false }));
            setSelectedCategory(-1);
            setSelectedLOB(-1);
          }}
          centered
        >
          <div className="row">
            <div className="col-12 px-0 mb-2">
              <span className="text-secondary mb-2">Select Offer LOB</span>
              <div>
                <select
                  className="form-select"
                  value={selectedLOB}
                  onChange={(e) => setSelectedLOB(e.target.value)}
                >
                  <option value={-1}>Select Offer LOB</option>
                  {lobList?.length > 0 &&
                    lobList?.map((item, index) => (
                      <option value={item?.id} key={index}>
                        {item?.lob}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-12 px-0">
              <span className="text-secondary mb-2">Select Category</span>
              <div className="d-flex flex-row">
                <div
                  className="d-flex align-items-center me-3"
                  style={{ width: "39%" }}
                >
                  <input
                    type="text"
                    className="form-control ms-2"
                    placeholder="Search Category"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <div style={{ width: "60%" }}>
                  <select
                    className="form-select"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value={-1}>Select Category</option>
                    {filterCategories(categoryList)?.map((item, index) => (
                      <option value={item?.id} key={index}>
                        {item?.category_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="col-3 mt-3 px-0">
              <FUSButton
                className={"form-control py-2"}
                buttonType="primary"
                labelText={"Map"}
                onClick={handleVendorLOBMapping}
                disabled={_.isEmpty(selectedLOB) || _.isEmpty(selectedCategory)}
              />
            </div>
          </div>
        </FUSModal>
      )}
    </>
  );
};

export default OfferLOBCategoryMap;

import api from "./api";

const getAllOrdersTaskDetails = () => {
  return api.post("/getAllOrdersTaskDetails");
};

const getTaskVegOrderDetailsBytaskId = (taskId) => {
  return api.post("/getTaskVegOrderDetailsBytaskId", { task_id: taskId });
};

const getAllOrdersByVendorId = (data) => {
  return api.post("/getAllOrdersByVendorId", data);
};

const addVendorsOrderConfig = (data) => {
  return api.post("/addVendorsOrderConfig", data);
};
const getVendorsOrderConfig = () => {
  return api.get("/getVendorsOrderConfig");
};
const getConfigforVendor = (data) => {
  return api.post("/getConfigforVendor", data);
};
const setConfigByVendor = (data) => {
  return api.post("/setConfigByVendor", data);
};
const updateConfigByVendor = (data) => {
  return api.post("/updateConfigByVendor", data);
};
const purchasingPlan = (date) => {
  return api.post("/getDeliveryPlanDataByVendorId", date);
};
const vendorConsolidatedOrder = (date) => {
  return api.post("/vendorConsolidatedOrder", date);
};
const getAllFranchiseeByVendorId = (vendor_id) => {
  return api.post("/getAllFranchiseeByVendorId", vendor_id);
};
const addPurchasingPlanByVendor = (data) => {
  return api.post("/addPurchasingPlanByVendor", data);
};
const deliveryPlanData = (data) => {
  return api.post("/deliveryPlanData", data);
};

const updatePurchasePlanForOrderProducts = (postdata) => {
  return api.post("updatePurchasePlanForOrderProducts", postdata);
};

const getLobByVendorId = (data) => {
  return api.post("/getLobByVendorIdForAdmin", data);
};
const getConfigDetailsForVendor = (data) => {
  return api.post("/getConfigDetailsForVendor", data);
};



const OrderService = {
  getAllOrdersTaskDetails,
  getTaskVegOrderDetailsBytaskId,
  getAllOrdersByVendorId,
  addVendorsOrderConfig,
  getVendorsOrderConfig,
  getConfigforVendor,
  setConfigByVendor,
  updateConfigByVendor,
  purchasingPlan,
  vendorConsolidatedOrder,
  getAllFranchiseeByVendorId,
  addPurchasingPlanByVendor,
  getLobByVendorId,
  deliveryPlanData,
  updatePurchasePlanForOrderProducts,
  getConfigDetailsForVendor,
};

export default OrderService;

/* eslint-disable no-unused-vars */
import AuthGuard from "../guards/AuthGuards";
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useContext } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import Dashboard from "../FUSScreens/Dashboard/Dashboard";
import FileUpload from "../FUSScreens/FileUpload/FileUpload";
import Agency from "../FUSScreens/Agency/Agency";
import CollectionData from "../FUSScreens/CollectionData/CollectionData";
import MakeCollector from "../FUSScreens/MakeCollector/MakeCollector";
import CollectorCode from "../FUSScreens/CollectorCode/CollectorCode";
import UploadDataWizard from "../FUSScreens/UploadDataWizard/UploadDataWizard";
import AdvanceMapping from "../FUSScreens/AdvanceMapping/AdvanceMapping";
import SourceType from "../Subscriptions/Componet/SourceType/SourceType";
import SubscriptionPlan from "../Subscriptions/Componet/Subscription/SubscriptionPlan";
import BusinessType from "../Subscriptions/Componet/BusinessType/BusinessType";
import FunctionalityList from "../Subscriptions/Componet/Functionality/FunctionalityList";
import FeatureFunctionality from "../Subscriptions/Componet/Mapping/FeatureFunctionality";
import FeatureListNew from "../Subscriptions/Componet/Feature/FeatureListNew";
import AddSubscription from "../Subscriptions/Componet/Subscription/AddSubscription";
import PlanBusiness from "../Subscriptions/Componet/Mapping/PlanBusiness";
import PlanPeriod from "../Subscriptions/Componet/PlanPeriod/PlanPeriod";
import AuthRoles from "../FUSScreens/AuthRoles/AuthRoles";
import CollectionReportHistory from "../FUSScreens/CollectionReportHistory/CollectionReportHistory";
import BODProcess from "../FUSScreens/BODProcess/BODProcess";
import EditAgency from "../FUSScreens/Agency/EditAgency";
import CheckCollectionDataExists from "../FUSScreens/MergedComp/MergedComp";
import GenerateCashMemo from "../FUSScreens/CashMemo/GenerateCashMemo";
import HistoryCashMemoReportWithDateRange from "../FUSScreens/CashMemo/HistoryCashMemoReportWithDateRange.jsx";
import UpdateMobileReq from "../FUSScreens/Retailer/UpdateMobileReq";
import ImportExelDataView from "../FUSScreens/CollectionReport/ImportExelDataView";
import CollectionReportBalance from "../FUSScreens/Collection Report Balance/CollectionReportBalance";
import BeatMapping from "../FUSScreens/BeatMapping/BeatMapping";
import QuickRegistration from "../FUSScreens/Agency/QuickRegistration";
import SendPushNotification from "../FUSScreens/Notification/SendPushNotification";
import OneTimeBeatUpload from "../FUSScreens/OneTimeBeatUpload/OneTimeUpload.jsx";
import BeatUploadMultiple from "../FUSScreens/BeatUploadMultiple/BeatUploadMultiple.jsx";
import UserHierarchy from "../FUSScreens/UserHierarchy/UserHierarchy";

import AddRole from "../FUSScreens/RoleAndFeature/AddRoles/AddRole.jsx";
import AssignRoles from "../FUSScreens/RoleAndFeature/AssignRole/AssignRoles.jsx";
import MapComponent from "../FUSScreens/MapComponent/MapComponent";
import ShowBeatDetail from "../FUSScreens/OneTimeBeatUpload/ShowBeatDetail.jsx";
import ViewExpense from "../FUSScreens/Settings/Expense/ViewExpense";
import VisitType from "../FUSScreens/Settings/VisitType/VisitType";
import ViewTerritory from "../FUSScreens/Settings/Territory/ViewTerritory";
import Holidays from "../FUSScreens/Settings/Holidays/Holidays";
import DepartmentMapping from "../FUSScreens/Settings/Depertment/DepartmentMapping";
import Department from "../FUSScreens/Settings/Depertment/Department";
import InactiveClients from "../FUSScreens/InactivecClients/InactiveClients.jsx";

import LOBConfiguration from "../FUSScreens/VendorsApproval/LOBConfiguration/LOBConfiguration.jsx";
import PurchasingPlan from "../FUSScreens/PurchasingPlan/PurchasingPlan.jsx";
import PackagingPlan from "../FUSScreens/PackagingPlan/PackagingPlan.jsx";

import AddBank from "../FUSScreens/Settings/AddBank/AddBank.jsx";
import CollectorRoutes from "../FUSScreens/CollectorRoutes/CollectorRoutes.jsx";
import CreateWorkflow from "../FUSScreens/Workflow/CreateWokflow/CreateWorkflow.jsx";
import ViewWorkflow from "../FUSScreens/Workflow/ViewWorkFlow/ViewWorkflow.jsx";
import ExpenseCategory from "../FUSScreens/Settings/Expense/ExpenseCategory.jsx";
import OpenPartyReport from "../FUSScreens/OpenPartyReport/OpenPartyReport.jsx";
import HistoryReportView from "../FUSScreens/ExportTableData/HistoryReportView.jsx";
import DailyCollectionView from "../FUSScreens/ExportTableData/DailyCollectionView.jsx";
import OpenCollectionFileUpload from "../FUSScreens/OpenCollectionFileUpload/OpenCollectionFileUpload.jsx";
import CollectionReportMarg from "../FUSScreens/ExportTableData/CollectionReportMarg.jsx";
import CollectorMap from "../FUSScreens/MapComponent/CollectorMap.jsx";
import HistoryCollectionReportWithMarg from "../FUSScreens/ExportTableData/HistoryCollectionReportWithMarg.jsx";
import CollectionInvoiceReport from "../FUSScreens/CollectionInvoiceReport/CollectionInvoiceReport";
import ViewGoDownMaster from "../FUSScreens/Godown/AddGodownInMaster.jsx";
import GodownMapping from "../FUSScreens/Godown/GodownMapping.jsx";
import Products from "../FUSScreens/Products/Products.jsx";
import SifyReport from "../FUSScreens/SifyReport/SifyReport.jsx";
import MasterFileUpload from "../FUSScreens/Sify/MasterFileUpload.jsx";
import SalesFileUpload from "../FUSScreens/Sify/SalesFileUpload.jsx";
import BankDepositReport from "../FUSScreens/BankDepositReport/BankDepositReport.jsx";
import AgencyList from "../FUSScreens/AgencyList/AgencyList.jsx";
import Orders from "../FUSScreens/Orders/Orders.jsx";
import PendingCollection from "../FUSScreens/PendingCollection/PendigCollection.jsx";
import CollectorDashboard from "../FUSScreens/Dashboard/CollectorDashboard.jsx";
import VendorsAprroval from "../FUSScreens/VendorsApproval/VendorsAprroval.jsx";
import Sifyitemmaster from "../FUSScreens/SifyReport/Sifyitemmaster.jsx";
import VendorConfiguration from "../FUSScreens/VendorsApproval/VendorConfiguration.jsx";
import VendorInvoicing from "../FUSScreens/VendorsApproval/VendorInvoicing.jsx";
import OrderConfig from "../FUSScreens/Orders/OrderConfig.jsx";
import Userlist from "../FUSScreens/UserList/Userlist.jsx";
import FetchOTP from "../FUSScreens/FetchOTP/FetchOTP.jsx";
import ManageUsers from "../FUSScreens/ManageUsers/ManageUsers.jsx";
import VendorHolidayConfiguration from "../FUSScreens/VendorsApproval/VendorHolidayConfiguration.jsx";
import VendorDiscount from "../FUSScreens/Discount/VendorDiscount.jsx";
import PurchaseFileUpload from "../FUSScreens/Sify/PurchaseFileUpload.jsx";
import ProductDiscount from "../FUSScreens/ProductsDiscount/ProductsDiscount.jsx";
import VendorOnBoarding from "../FUSScreens/VendorsApproval/VendorOnBoarding.jsx";
import OrderTimeConfig from "../FUSScreens/OrderTimeConfig/OrderTimeConfig.jsx";
import SectionConfiguration from "../FUSScreens/VendorUiConfiguration/SectionConfiguration.jsx";
import PartyMaster from "../FUSScreens/FuseitBusiness/PartyMaster/PartyMaster.jsx";
import ManageCategories from "../FUSScreens/FuseitBusiness/Departments/ManageCategories.jsx";
import OfferConfig from "../FUSScreens/SendVendorNotification/OfferConfig.jsx";
import ViewDispatch from "../FUSScreens/FuseitBusiness/Dispatch/ViewDispatch.jsx";
import AppServices from "../FUSScreens/AppServices/AppServices.jsx";
import RideCoupon from "../FUSScreens/RideCoupon/RideCoupon.jsx";
import ViewOrderB2B from "../FUSScreens/FuseitBusiness/Orders/ViewOrdersB2B.jsx";
import ManageDepartments from "../FUSScreens/FuseitBusiness/Departments/ManageDepartments.jsx";
import MakeRideManager from "../FUSScreens/Ride Manager/MakeRideManager.jsx";
import MakeDriver from "../FUSScreens/Ride Manager/MakeDriver.jsx";
import ManageCategory from "../FUSScreens/VendorsApproval/LOBConfiguration/ManageCategory.jsx";
import RideTimeConfig from "../FUSScreens/RideTimeConfig/RideTimeConfig.jsx";
import Service from "../FUSScreens/Service/Service.jsx";
import MapDepartmentToUser from "../FUSScreens/FuseitBusiness/Departments/MapDepartmentToUser.jsx";
import NotificationWizard from "../FUSScreens/Notification/NotificationWizard.jsx";
import FinancialYear from "../FUSScreens/FinancialYear/FinancialYear.jsx";
import RideType from "../FUSScreens/RideType/RideType.jsx";
import VehicleType from "../FUSScreens/VehicleType/VehicleType.jsx";
import DriverType from "../FUSScreens/DriverType/DriverType.jsx";
import DeliveryFeesConfig from "../FUSScreens/DeliveryFees/DeliveryFeesConfig.jsx";
import SystemConfig from "../FUSScreens/SystemConfig/SystemConfig.jsx";
import OrderWizard from "../FUSScreens/VendorProductWizards/OrderWizard.jsx";
import DeliverySlotWizard from "../FUSScreens/VendorProductWizards/DeliverySlotWizard.jsx";
import VendorLOBMapping from "../FUSScreens/VendorsApproval/VendorLOBMapping.jsx";

import PermissionWizard from "../FUSScreens/RoleAndFeature/PermissionUpdateWizard/PermissionWizard.jsx";
import ProductWizard from "../FUSScreens/VendorProductWizards/ProductWizard.jsx";
import ShowAllOrders from "../FUSScreens/Orders/ShowAllOrders.jsx";
import HolidayAndOrderWizard from "../FUSScreens/HolidayAndOrderWizard/HolidayAndOrderWizard.jsx";
import OfferCoupon from "../FUSScreens/OfferCoupon/OfferCoupon.jsx";
import AddServiceRepresentative from "../FUSScreens/AddServiceRepresentative/AddServiceRepresentative.jsx";
import AddRewardPoint from "../FUSScreens/Wallet/AddRewardPoint.jsx";
import VendorOrderReports from "../FUSScreens/VendorOrderReports/VendorOrderReports.jsx";
import LocationImageMapping from "../FUSScreens/LocationImageMapping/LocationImageMapping.jsx";
import AssignDeliveryPersonRole from "../FUSScreens/RoleAndFeature/AssignDeliveryPerosnRole/AssignDeliveryPersonRole.jsx";
import QuizAndEarnReward from "../FUSScreens/Wallet/QuizAndEarnReward.jsx";
import OrdersDashboard from "../FUSScreens/ClientsOrder/OrdersDashboard.jsx";
import VendorProductManagement from "../FUSScreens/VendorProductManagement/VendorProductManagement.jsx";
import ProductsPrice from "../FUSScreens/ProductsPrice/ProductsPrice.jsx";
import AddServiceTerms from "../FUSScreens/AddServiceTerms/AddServiceTerms.jsx";
import VendorSlotConfiguration from "../FUSScreens/VendorSlotConfiguration/VendorSlotConfiguration.jsx";
import OfferLOBConfiguration from "../FUSScreens/OfferLOBConfig/OfferLOBConfiguration.jsx";
import LOBSectionConfiguration from "../FUSScreens/VendorUiConfiguration/LOBSectionConfiguration.jsx";
import WalletDetails from "../FUSScreens/Wallet/WalletDetails.jsx";

const AuthRoutes = () => {
  const [RetailerRequest, setRetailerRequest] = useState();
  const getRequestCount = (count) => {
    setRetailerRequest(count);
  };

  return (
    <Routes>
      <>
        <Route
          exact
          path="/inactiveclients"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"inactiveclients"}
                  component={<InactiveClients />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/"
          element={
            <AuthGuard
              component={<AuthRoles name={"/"} component={<Dashboard />} />}
            />
          }
        />
        <Route
          exact
          path="/dashboard"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"dashboard"}
                  component={<UploadDataWizard />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/fileupload"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"fileupload"} component={<FileUpload />} />
              }
            />
          }
        />
        <Route
          exact
          path="/createagency"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"createagency"} component={<Agency />} />
              }
            />
          }
        />
        <Route
          exact
          path="/collectiondata"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"collectiondata"}
                  component={<CollectionData />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/makecollector"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"makecollector"}
                  component={<MakeCollector />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/mapping"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"mapping"} component={<AdvanceMapping />} />
              }
            />
          }
        />
        <Route
          exact
          path="/collectorcode"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"collectorcode"}
                  component={<CollectorCode />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/advancemapping"
          element={<AuthGuard component={<AdvanceMapping />} />}
        />
        <Route
          exact
          path="/sourcetype"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"sourcetype"} component={<SourceType />} />
              }
            />
          }
        />
        <Route
          exact
          path="/subscriptionplan"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"subscriptionplan"}
                  component={<SubscriptionPlan />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/vendorsApproval"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"vendorsApproval"}
                  component={<VendorsAprroval />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/vendorConfiguration"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"vendorConfiguration"}
                  component={<VendorConfiguration />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/vendorInvoicing"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"vendorInvoicing"}
                  component={<VendorInvoicing />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/businesstype"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"businesstype"} component={<BusinessType />} />
              }
            />
          }
        />
        <Route
          exact
          path="/featurelist"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"featurelist"}
                  component={<FeatureListNew />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/functionality"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"functionality"}
                  component={<FunctionalityList />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/featurefunctionality"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"featurefunctionality"}
                  component={<FeatureFunctionality />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/addsubscription"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"addsubscription"}
                  component={<AddSubscription />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/planbusiness"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"planbusiness"} component={<PlanBusiness />} />
              }
            />
          }
        />
        <Route
          exact
          path="/planperiod"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"planperiod"} component={<PlanPeriod />} />
              }
            />
          }
        />
        <Route
          path="/collectionReport"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"collectionReport"}
                  component={<DailyCollectionView />}
                />
              }
            />
          }
        />
        <Route
          path="/collectionReportHistory"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"collectionReportHistory"}
                  component={<CollectionReportHistory />}
                />
              }
            />
          }
        />
        <Route
          path="/collectionHistoryWithRange"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"collectionHistoryWithRange"}
                  component={<HistoryReportView />}
                />
              }
            />
          }
        />
        <Route
          path="/bodprocess"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"bodprocess"} component={<BODProcess />} />
              }
            />
          }
        />
        <Route
          path="/updateAgency"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"updateAgency"} component={<EditAgency />} />
              }
            />
          }
        />
        <Route
          path="/beatUpload"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"beatUpload"}
                  component={<CheckCollectionDataExists />}
                />
              }
            />
          }
        />
        <Route
          path="/cashmemo"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"cashmemo"} component={<GenerateCashMemo />} />
              }
            />
          }
        />
        <Route
          path="/cashmemohistory"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"cashmemohistory"}
                  component={<HistoryCashMemoReportWithDateRange />}
                />
              }
            />
          }
        />
        <Route
          path="/updateretailerreq"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"updateretailerreq"}
                  component={
                    <UpdateMobileReq getRequestCount={getRequestCount} />
                  }
                />
              }
            />
          }
        />
        <Route
          path="/beatFileMapping"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"beatFileMapping"}
                  component={<BeatMapping />}
                />
              }
            />
          }
        />
        <Route
          path="/updateretailerreq"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"updateretailerreq"}
                  component={
                    <UpdateMobileReq getRequestCount={getRequestCount} />
                  }
                />
              }
            />
          }
        />
        <Route
          path="/uploadexceldata"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"uploadexceldata"}
                  component={<ImportExelDataView />}
                />
              }
            />
          }
        />
        <Route
          path="/collectionreportBalance"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"collectionreportBalance"}
                  component={<CollectionReportBalance />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/quickregistration"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"quickregistration"}
                  component={<QuickRegistration />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/beatuploadmultiple"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"beatuploadmultiple"}
                  component={<BeatUploadMultiple />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/onetimeupload"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"onetimeupload"}
                  component={<OneTimeBeatUpload />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/pushnotification"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"pushnotification"}
                  component={<SendPushNotification />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/userhierarchy"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"userhierarchy"}
                  component={<UserHierarchy />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/createRole"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"createRole"} component={<AddRole />} />
              }
            />
          }
        />
        <Route
          exact
          path="/assignRole"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"assignRole"} component={<AssignRoles />} />
              }
            />
          }
        />
        <Route
          exact
          path="/showbeatdetail"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"showbeatdetail"}
                  component={<ShowBeatDetail />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/sifyitemmaster"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"sifyitemmaster"}
                  component={<Sifyitemmaster />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/tracking"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"tracking"} component={<MapComponent />} />
              }
            />
          }
        />
        <Route
          exact
          path="/products"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"products"} component={<Products />} />
              }
            />
          }
        />
        <Route
          exact
          path="/orders"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"orders"} component={<OrderWizard />} />
              }
            />
          }
        />
        <Route
          exact
          path="/ordersConfiguration"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"ordersConfiguration"}
                  component={<OrderConfig />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/packagingPlan"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"packagingPlan"}
                  component={<PackagingPlan />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/purchasingPlan"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"purchasingPlan"}
                  component={<PurchasingPlan />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/manageservices"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"manageservices"} component={<Service />} />
              }
            />
          }
        />
        <Route
          exact
          path="/vendorCoupon"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"vendorCoupon"}
                  component={<VendorDiscount />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/expense"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"expense"} component={<ViewExpense />} />
              }
            />
          }
        />
        <Route
          exact
          path="/visitType"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"visitType"} component={<VisitType />} />
              }
            />
          }
        />
        <Route
          exact
          path="/viewterritory"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"viewterritory"}
                  component={<ViewTerritory />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/holidays"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"holidays"} component={<Holidays />} />
              }
            />
          }
        />
        <Route
          exact
          path="/departmentMapping"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"departmentMapping"}
                  component={<DepartmentMapping />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/department"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"department"} component={<Department />} />
              }
            />
          }
        />
        <Route
          exact
          path="/addbank"
          element={
            <AuthGuard
              component={<AuthRoles name={"addbank"} component={<AddBank />} />}
            />
          }
        />
        <Route
          exact
          path="/collectorroutes"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"collectorroutes"}
                  component={<CollectorRoutes />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/createWorkflow"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"createWorkflow"}
                  component={<CreateWorkflow />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/viewWorkflow"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"viewWorkflow"} component={<ViewWorkflow />} />
              }
            />
          }
        />
        <Route
          exact
          path="/expenseCategory"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"expenseCategory"}
                  component={<ExpenseCategory />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/openPartyReport"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"openPartyReport"}
                  component={<OpenPartyReport />}
                />
              }
            />
          }
        />
        <Route
          path="/openCollectionFileUpload"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"openCollectionFileUpload"}
                  component={<OpenCollectionFileUpload />}
                />
              }
            />
          }
        />
        <Route
          path="/collectionreportmarg"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"CollectionReportMarg"}
                  component={<CollectionReportMarg />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/collectorMap"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"collectorMap"} component={<CollectorMap />} />
              }
            />
          }
        />
        <Route
          path="/collectionInvoiceReport"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"collectionInvoiceReport"}
                  component={<CollectionInvoiceReport />}
                />
              }
            />
          }
        />
        <Route
          path="/HistoryCollectionMarg"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"historyCollectionReportWithMarg"}
                  component={<HistoryCollectionReportWithMarg />}
                />
              }
            />
          }
        />
        <Route
          path="/godownMaster"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"godownMaster"}
                  component={<ViewGoDownMaster />}
                />
              }
            />
          }
        />
        <Route
          path="/godownMapping"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"godownMapping"}
                  component={<GodownMapping />}
                />
              }
            />
          }
        />
        <Route
          path="/sifyReport"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"sifyReport"} component={<SifyReport />} />
              }
            />
          }
        />
        <Route
          path="/masterFileUpload"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"masterFileUpload"}
                  component={<MasterFileUpload />}
                />
              }
            />
          }
        />
        <Route
          path="/salesFileUpload"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"salesFileUpload"}
                  component={<SalesFileUpload />}
                />
              }
            />
          }
        />
        <Route
          path="/purchaseFileUpload"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"purchaseFileUpload"}
                  component={<PurchaseFileUpload />}
                />
              }
            />
          }
        />
        <Route
          path="/bankDepositReport"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"bankDepositReport"}
                  component={<BankDepositReport />}
                />
              }
            />
          }
        />
        <Route
          path="/agencylist"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"agencylist"} component={<AgencyList />} />
              }
            />
          }
        />
        <Route
          path="/cashFlow"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"cashFlow"}
                  component={<PendingCollection />}
                />
              }
            />
          }
        />
        <Route
          path="/collectorDashboard"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"collectorDashboard"}
                  component={<CollectorDashboard />}
                />
              }
            />
          }
        />
        <Route
          path="/userlist"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"userlist"} component={<Userlist />} />
              }
            />
          }
        />
        <Route
          path="/fetchOTP"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"fetchOTP"} component={<FetchOTP />} />
              }
            />
          }
        />
        <Route
          path="/lobConfiguration"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"lobConfiguration"}
                  component={<LOBConfiguration />}
                />
              }
            />
          }
        />
        <Route
          path="/showAllOrders"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"showAllOrders"}
                  component={<ShowAllOrders />}
                />
              }
            />
          }
        />
        <Route
          path="/manageUsers"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"manageUsers"} component={<ManageUsers />} />
              }
            />
          }
        />
        <Route
          path="/holidayconfig"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"holidayconfig"}
                  component={<HolidayAndOrderWizard />}
                />
              }
            />
          }
        />
        <Route
          path="/manageSlots"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"manageSlots"}
                  component={<OrderTimeConfig />}
                />
              }
            />
          }
        />
        <Route
          path="/productItemDiscount"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"productItemDiscount"}
                  component={<ProductDiscount />}
                />
              }
            />
          }
        />
        <Route
          path="/slotConfig"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"vendorSlotConfiguration"}
                  component={<VendorSlotConfiguration/>}
                />
              }
            />
          }
        />
        <Route
          path="/offerConfig"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"offerConfig"} component={<OfferConfig />} />
              }
            />
          }
        />
        <Route
          path="/orderTimeConfig"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"orderTimeConfig"}
                  component={<OrderTimeConfig />}
                />
              }
            />
          }
        />
        <Route
          path="/vendorOnboarding"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"vendorOnboarding"}
                  component={<VendorOnBoarding />}
                />
              }
            />
          }
        />
        <Route
          path="/sectionConfiguration"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"sectionConfiguration"}
                  component={<SectionConfiguration />}
                />
              }
            />
          }
        />
        <Route
          path="/partyMaster"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"partyMaster"} component={<PartyMaster />} />
              }
            />
          }
        />
        <Route
          path="/manageManufacturingDepartment"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"manageManufacturingDepartment"}
                  component={<ManageDepartments />}
                />
              }
            />
          }
        />
        <Route
          path="/addCategory"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"addCategory"}
                  component={<ManageCategory />}
                />
              }
            />
          }
        />
        <Route
          path="/manageCategory"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"manageCategory"}
                  component={<ManageCategories />}
                />
              }
            />
          }
        />
        <Route
          path="/viewDispatch"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"viewDispatch"} component={<ViewDispatch />} />
              }
            />
          }
        />
        <Route
          path="/appservices"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"appservices"} component={<AppServices />} />
              }
            />
          }
        />
        <Route
          path="/viewOrders"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"viewOrders"} component={<ViewOrderB2B />} />
              }
            />
          }
        />
        <Route
          path="/makeRideManager"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"makeRideManager"}
                  component={<MakeRideManager />}
                />
              }
            />
          }
        />
        <Route
          path="/rideCoupon"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"rideCoupon"} component={<RideCoupon />} />
              }
            />
          }
        />
        <Route
          path="/rideConfig"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"rideConfig"} component={<RideTimeConfig />} />
              }
            />
          }
        />
        <Route
          path="/rideType"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"rideType"} component={<RideType />} />
              }
            />
          }
        />
        <Route
          path="/vehicleType"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"vehicleType"} component={<VehicleType />} />
              }
            />
          }
        />
        <Route
          path="/driverType"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"driverType"} component={<DriverType />} />
              }
            />
          }
        />
        <Route
          path="/makeDriver"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"makeDriver"} component={<MakeDriver />} />
              }
            />
          }
        />
        <Route
          path="/mapDepartmentToUser"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"mapDepartmentToUser"}
                  component={<MapDepartmentToUser />}
                />
              }
            />
          }
        />
        <Route
          path="/manageNotification"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"manageNotification"}
                  component={<NotificationWizard />}
                />
              }
            />
          }
        />
        <Route
          path="/financialYear"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"financialYear"}
                  component={<FinancialYear />}
                />
              }
            />
          }
        />
        <Route
          path="/deliveryFeesConfig"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"deliveryFeesConfig"}
                  component={<DeliveryFeesConfig />}
                />
              }
            />
          }
        />
        <Route
          path="/systemConfig"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"systemConfig"} component={<SystemConfig />} />
              }
            />
          }
        />
        <Route
          path="/manageProducts"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"manageProducts"}
                  component={<ProductWizard />}
                />
              }
            />
          }
        />
        <Route
          path="/vendorLOBMapping"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"vendorLOBMapping"}
                  component={<VendorLOBMapping />}
                />
              }
            />
          }
        />
        <Route
          path="/manageProducts"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"manageProducts"}
                  component={<ProductWizard />}
                />
              }
            />
          }
        />
        <Route
          path="/updateFunctionalityList"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"updateFunctionalityList"}
                  component={<PermissionWizard />}
                />
              }
            />
          }
        />
        <Route
          path="/offerCoupon"
          element={
            <AuthGuard
              component={
                <AuthRoles name={"offerCoupon"} component={<OfferCoupon />} />
              }
            />
          }
        />
        <Route
          path="/addServiceRepresentative"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"addServiceRepresentative"}
                  component={<AddServiceRepresentative />}
                />
              }
            />
          }
        />
        <Route
          path="/addrewardpoint"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"addrewardpoint"}
                  component={<AddRewardPoint />}
                />
              }
            />
          }
        />
        <Route
          path="/assignDeliveryPerson"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"assignDeliveryPerson"}
                  component={<AssignDeliveryPersonRole />}
                />
              }
            />
          }
        />
        <Route
          path="/locationImageMapping"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"locationImageMapping"}
                  component={<LocationImageMapping />}
                />
              }
            />
          }
        />
        <Route
          path="/vendorOrderReports"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"vendorOrderReports"}
                  component={<VendorOrderReports />}
                />
              }
            />
          }
        />

        <Route
          path="/quizAndEarnReward"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"quizAndEarnReward"}
                  component={<QuizAndEarnReward />}
                />
              }
            />
          }
        />

        <Route
          path="/vendorProductManagement"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"vendorProductManagement"}
                  component={<VendorProductManagement />}
                />
              }
            />
          }
        />

        <Route
          path="/clientOrderDashboard"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"clientOrderDashboard"}
                  component={<OrdersDashboard />}
                />
              }
            />
          }
        />
        <Route
          path="/productsprice"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"productsprice"}
                  component={<ProductsPrice />}
                />
              }
            />
          }
        />
         <Route
          path="/serviceTerms"
          element={
            <AuthGuard
              component={
                <AuthRoles
                name={"serviceTerms"}
                  component={<AddServiceTerms/>}
                  />
                }
              />
            }
          />
        <Route
          path="/offerLOBConfiguration"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"offerLOBConfiguration"}
                  component={<OfferLOBConfiguration />}
                />
              }
            />
          }
        />
        <Route
          path="/lobSectionConfiguration"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"lobSectionConfiguration"}
                  component={<LOBSectionConfiguration />}
                />
              }
            />
          }
        />
         <Route
          path="/walletDetails"
          element={
            <AuthGuard
              component={
                <AuthRoles
                  name={"walletDetails"}
                  component={<WalletDetails />}
                />
              }
            />
          }
        />
      </>
    </Routes>
  );
};

export default AuthRoutes;

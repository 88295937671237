import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import ServiceProvider from "../../services/serviceprovider.service";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import OfferService from "../../services/offer.service";

function WalletDetails() {
  const [searchQuery, setSearchQuery] = useState("");
  const [walletPointsData, setWalletPointsData] = useState([]);
  const [filteredWalletPointsData, setFilteredWalletPointsData] = useState([]);

  const getWalletDetails = async () => {
    await OfferService.getWalletPoints()
      .then((res) => {
        if (res?.data?.errorCode === 0) {
          setWalletPointsData(res?.data?.data);
          setFilteredWalletPointsData(res?.data?.data); 
        } else {
          setWalletPointsData([]);
          setFilteredWalletPointsData([]);
        }
      })
      .catch((error) => {
        requestAndErrorHandler.errorHandler(error);
      });
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filteredData = walletPointsData.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredWalletPointsData(filteredData);
  };

  const columns = [
    {
      dataField: "name",
      text: "Client Name",
     
    },
    {
      dataField: "login_phone",
      text: "Phone Number",
    },
    {
      dataField: "wallet_cash_points",
      text: "Wallet Points",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "All", value: walletPointsData.length },
    ],
  };

  useEffect(() => {
    getWalletDetails();
  }, []);

  return (
    <LayoutContainer title2="Wallet" title3="Wallet Details">
      <div className="border_bottom_style">
        <div className="row">
          <div className="col-md-3">
            <input
              type="text"
              className="form-control text_style mt-1"
              placeholder="Search Here......."
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="px-3 py-3">
          <div className="col-md-12">
            <BootstrapTable
              classes="border border-1"
              keyField="id"
              data={filteredWalletPointsData} 
              columns={columns}
              pagination={paginationFactory(featureListOption)}
              filter={filterFactory()}
            />
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
}

export default WalletDetails;
